export const cpus = [
    {key: 0, text: "AMD EPYC 7T83 64-Core", value: 0, h: 103706},
    {key: 1, text: "AMD EPYC 7763 64-Core", value: 1, h: 101447},
    {key: 2, text: "AMD EPYC 7J13 64-Core", value: 2, h: 99343},
    {key: 3, text: "AMD EPYC 7742 64-Core", value: 3, h: 98162},
    {key: 4, text: "AMD EPYC 7H12 64-Core", value: 4, h: 95767},
    {key: 5, text: "AMD EPYC 7713 64-Core", value: 5, h: 93715},
    {key: 6, text: "AMD EPYC 7B12 64-Core", value: 6, h: 92940},
    {key: 7, text: "AMD EPYC 7702 64-Core", value: 7, h: 85965},
    {key: 8, text: "AMD EPYC 7662 64-Core", value: 8, h: 85846},
    {key: 9, text: "AMD EPYC 7R32 48-Core", value: 9, h: 83514},
    {key: 10, text: "AMD EPYC 7K83 64-Core", value: 10, h: 82851},
    {key: 11, text: "AMD EPYC 7K62 48-Core", value: 11, h: 82495},
    {key: 12, text: "AMD EPYC 7R13 48-Core", value: 12, h: 81780},
    {key: 13, text: "AMD EPYC 9654 96-Core", value: 13, h: 80064},
    {key: 14, text: "AMD EPYC 7552 48-Core", value: 14, h: 71423},
    {key: 15, text: "AMD EPYC 7642 48-Core", value: 15, h: 70512},
    {key: 16, text: "AMD Ryzen Threadripper 3990X 64-Core", value: 16, h: 65117},
    {key: 17, text: "AMD EPYC 7V12 64-Core", value: 17, h: 60263},
    {key: 18, text: "AMD EPYC 7532 32-Core", value: 18, h: 58696},
    {key: 19, text: "Intel Xeon Platinum 8153 CPU @ 2.00GHz", value: 19, h: 54570},
    {key: 20, text: "AMD Ryzen Threadripper PRO 3995WX 64-Cores", value: 20, h: 54259},
    {key: 21, text: "AMD EPYC 7542 32-Core", value: 21, h: 53981},
    {key: 22, text: "AMD EPYC 7452 32-Core", value: 22, h: 53763},
    {key: 23, text: "AMD Ryzen Threadripper PRO 5995WX 64-Cores", value: 23, h: 52671},
    {key: 24, text: "Intel Xeon Platinum 8176 CPU @ 2.10GHz", value: 24, h: 50344},
    {key: 25, text: "AMD EPYC 7B13 64-Core", value: 25, h: 49193},
    {key: 26, text: "Intel Xeon Platinum 8268 CPU @ 2.90GHz", value: 26, h: 46814},
    {key: 27, text: "AMD EPYC 7402 24-Core", value: 27, h: 45399},
    {key: 28, text: "AMD EPYC 7R12 48-Core", value: 28, h: 44871},
    {key: 29, text: "AMD EPYC 7V13 64-Core", value: 29, h: 44747},
    {key: 30, text: "Intel Xeon Gold 6438N", value: 30, h: 43516},
    {key: 31, text: "AMD EPYC 7F72 24-Core", value: 31, h: 43457},
    {key: 32, text: "AMD EPYC 7713P 64-Core", value: 32, h: 42902},
    {key: 33, text: "AMD EPYC 7543 32-Core", value: 33, h: 42685},
    {key: 34, text: "Intel Xeon CPU E7-8890 v4 @ 2.20GHz", value: 34, h: 42642},
    {key: 35, text: "Intel Xeon Gold 6238 CPU @ 2.10GHz", value: 35, h: 42602},
    {key: 36, text: "AMD EPYC 7413 24-Core", value: 36, h: 42519},
    {key: 37, text: "AMD Ryzen Threadripper 3970X 32-Core", value: 37, h: 42366},
    {key: 38, text: "AMD EPYC 7702P 64-Core", value: 38, h: 42045},
    {key: 39, text: "AMD EPYC 74F3 24-Core", value: 39, h: 41124},
    {key: 40, text: "AMD EPYC 7663 56-Core", value: 40, h: 40394},
    {key: 41, text: "Intel Xeon Platinum 8378C CPU @ 2.80GHz", value: 41, h: 40307},
    {key: 42, text: "AMD EPYC 7R32", value: 42, h: 38725},
    {key: 43, text: "AMD EPYC 7601 32-Core", value: 43, h: 37839},
    {key: 44, text: "Intel Xeon Platinum 8375C CPU @ 2.90GHz", value: 44, h: 35737},
    {key: 45, text: "Intel Xeon Gold 6150 CPU @ 2.70GHz", value: 45, h: 35433},
    {key: 46, text: "AMD EPYC 7352 24-Core", value: 46, h: 35078},
    {key: 47, text: "AMD Ryzen Threadripper PRO 3975WX 32-Cores", value: 47, h: 34984},
    {key: 48, text: "AMD EPYC 7B12", value: 48, h: 34463},
    {key: 49, text: "AMD EPYC 9354P 32-Core", value: 49, h: 33786},
    {key: 50, text: "AMD EPYC 7501 32-Core", value: 50, h: 33244},
    {key: 51, text: "AMD EPYC 7551 32-Core", value: 51, h: 32391},
    {key: 52, text: "Intel Xeon Platinum 8280M CPU @ 2.70GHz", value: 52, h: 32342},
    {key: 53, text: "AMD Ryzen Threadripper 3960X 24-Core", value: 53, h: 30672},
    {key: 54, text: "Intel Xeon Platinum 8358 CPU @ 2.60GHz", value: 54, h: 30251},
    {key: 55, text: "AMD EPYC 7313 16-Core", value: 55, h: 29715},
    {key: 56, text: "Intel Xeon CPU E7-8890 v3 @ 2.50GHz", value: 56, h: 29714},
    {key: 57, text: "Intel Xeon Platinum 8370C CPU @ 2.80GHz", value: 57, h: 29594},
    {key: 58, text: "Intel Xeon Platinum 8275CL CPU @ 3.00GHz", value: 58, h: 29499},
    {key: 59, text: "Intel Xeon Platinum 8269CY CPU @ 2.50GHz", value: 59, h: 28971},
    {key: 60, text: "AMD EPYC 7302 16-Core", value: 60, h: 28720},
    {key: 61, text: "AMD EPYC 7502P 32-Core", value: 61, h: 28413},
    {key: 62, text: "AMD EPYC 7282 16-Core", value: 62, h: 28197},
    {key: 63, text: "Intel Xeon Platinum 8352Y CPU @ 2.20GHz", value: 63, h: 28123},
    {key: 64, text: "AMD Ryzen Threadripper PRO 5975WX 32-Cores", value: 64, h: 27743},
    {key: 65, text: "AMD EPYC 7D12 32-Core", value: 65, h: 27693},
    {key: 66, text: "Intel Xeon Platinum 8260 CPU @ 2.40GHz", value: 66, h: 26932},
    {key: 67, text: "AMD Ryzen 9 7950X 16-Core", value: 67, h: 26269},
    {key: 68, text: "Intel Xeon Platinum 8180 CPU @ 2.50GHz", value: 68, h: 26196},
    {key: 69, text: "AMD Ryzen 9 7950X3D 16-Core", value: 69, h: 25735},
    {key: 70, text: "Intel Xeon Gold 6230R CPU @ 2.10GHz", value: 70, h: 25489},
    {key: 71, text: "AMD EPYC 7Y43 48-Core", value: 71, h: 25489},
    {key: 72, text: "Intel Xeon Gold 6240R CPU @ 2.40GHz", value: 72, h: 25280},
    {key: 73, text: "AMD EPYC 7401 24-Core", value: 73, h: 25164},
    {key: 74, text: "AMD Ryzen Threadripper PRO 5965WX 24-Cores", value: 74, h: 25008},
    {key: 75, text: "Intel Xeon CPU E7-8880 v3 @ 2.30GHz", value: 75, h: 24749},
    {key: 76, text: "Intel Xeon Platinum 8272CL CPU @ 2.60GHz", value: 76, h: 24580},
    {key: 77, text: "Intel Xeon Gold 6258R CPU @ 2.70GHz", value: 77, h: 24576},
    {key: 78, text: "Intel Xeon Gold 6248R CPU @ 3.00GHz", value: 78, h: 24548},
    {key: 79, text: "Intel Xeon CPU E7-8895 v2 @ 2.80GHz", value: 79, h: 24245},
    {key: 80, text: "AMD EPYC 7773X 64-Core", value: 80, h: 24002},
    {key: 81, text: "Intel Xeon Platinum 8171M CPU @ 2.60GHz", value: 81, h: 23671},
    {key: 82, text: "AMD Ryzen 9 5950X 16-Core", value: 82, h: 23370},
    {key: 83, text: "AMD EPYC 7571", value: 83, h: 23214},
    {key: 84, text: "Intel Xeon Gold 6252 CPU @ 2.10GHz", value: 84, h: 23207},
    {key: 85, text: "Intel Xeon W-3175X CPU @ 3.10GHz", value: 85, h: 22938},
    {key: 86, text: "Intel Xeon Platinum 8124M CPU @ 3.00GHz", value: 86, h: 22710},
    {key: 87, text: "Intel Xeon Platinum 8259L CPU @ 2.50GHz", value: 87, h: 22679},
    {key: 88, text: "AMD Ryzen 9 3950X 16-Core", value: 88, h: 22596},
    {key: 89, text: "Intel Xeon Gold 5220R CPU @ 2.20GHz", value: 89, h: 22540},
    {key: 90, text: "Intel Xeon Platinum 8160 CPU @ 2.10GHz", value: 90, h: 22284},
    {key: 91, text: "Intel Xeon CPU E7- 4870 @ 2.40GHz", value: 91, h: 22281},
    {key: 92, text: "AMD EPYC 7402P 24-Core", value: 92, h: 22252},
    {key: 93, text: "Intel Xeon Gold 6238R CPU @ 2.20GHz", value: 93, h: 21956},
    {key: 94, text: "Intel Xeon Gold 6248 CPU @ 2.50GHz", value: 94, h: 21597},
    {key: 95, text: "AMD Ryzen Threadripper 2990WX 32-Core", value: 95, h: 21455},
    {key: 96, text: "AMD EPYC 7443P 24-Core", value: 96, h: 21059},
    {key: 97, text: "Intel Xeon Gold 6246R CPU @ 3.40GHz", value: 97, h: 20562},
    {key: 98, text: "Intel Xeon Platinum 8222L CPU @ 3.00GHz", value: 98, h: 20550},
    {key: 99, text: "Intel Xeon CPU E7-8891 v2 @ 3.20GHz", value: 99, h: 19921},
    {key: 100, text: "AMD EPYC 7451 24-Core", value: 100, h: 19876},
    {key: 101, text: "Intel Xeon CPU E7-8891 v3 @ 2.80GHz", value: 101, h: 19723},
    {key: 102, text: "AMD EPYC 7551P 32-Core", value: 102, h: 19645},
    {key: 103, text: "Intel Xeon Gold 6148 CPU @ 2.40GHz", value: 103, h: 19589},
    {key: 104, text: "AMD Ryzen 9 7900X 12-Core", value: 104, h: 19358},
    {key: 105, text: "Intel Xeon Gold 6230 CPU @ 2.10GHz", value: 105, h: 19213},
    {key: 106, text: "Intel Xeon Gold 6138 CPU @ 2.00GHz", value: 106, h: 19039},
    {key: 107, text: "AMD Ryzen Threadripper PRO 3955WX 16-Cores", value: 107, h: 19008},
    {key: 108, text: "Intel Xeon Platinum 8280L CPU @ 2.70GHz", value: 108, h: 18987},
    {key: 109, text: "Intel Xeon CPU E5-2699 v3 @ 2.30GHz", value: 109, h: 18729},
    {key: 110, text: "Intel Xeon Gold 6354 CPU @ 3.00GHz", value: 110, h: 18634},
    {key: 111, text: "Intel Xeon Gold 6242 CPU @ 2.80GHz", value: 111, h: 18586},
    {key: 112, text: "Intel Xeon CPU E5-2696 v4 @ 2.20GHz", value: 112, h: 18548},
    {key: 113, text: "Intel Xeon CPU E5-4657L v2 @ 2.40GHz", value: 113, h: 18486},
    {key: 114, text: "AMD Ryzen 9 7900 12-Core", value: 114, h: 18420},
    {key: 115, text: "Intel Xeon Platinum 8175M CPU @ 2.50GHz", value: 115, h: 18373},
    {key: 116, text: "Intel Xeon Platinum 8259CL CPU @ 2.50GHz", value: 116, h: 18253},
    {key: 117, text: "Intel Xeon Gold 6226R CPU @ 2.90GHz", value: 117, h: 18094},
    {key: 118, text: "Intel Xeon Platinum 8168 CPU @ 2.70GHz", value: 118, h: 18046},
    {key: 119, text: "Intel Xeon CPU E5-2699 v4 @ 2.20GHz", value: 119, h: 18018},
    {key: 120, text: "Intel Xeon Gold 6139 CPU @ 2.30GHz", value: 120, h: 17760},
    {key: 121, text: "AMD EPYC 7513 32-Core", value: 121, h: 17603},
    {key: 122, text: "AMD Ryzen 9 3900X 12-Core", value: 122, h: 17596},
    {key: 123, text: "Intel Xeon Gold 5218 CPU @ 2.30GHz", value: 123, h: 17506},
    {key: 124, text: "AMD Ryzen 9 5900X 12-Core", value: 124, h: 17430},
    {key: 125, text: "Intel Xeon Gold 6130 CPU @ 2.10GHz", value: 125, h: 17365},
    {key: 126, text: "Intel Xeon CPU E5-2696 v3 @ 2.30GHz", value: 126, h: 17284},
    {key: 127, text: "Intel Xeon CPU E5-2698 v4 @ 2.20GHz", value: 127, h: 17202},
    {key: 128, text: "Intel Xeon Gold 5218R CPU @ 2.10GHz", value: 128, h: 17143},
    {key: 129, text: "AMD Ryzen Threadripper 2970WX 24-Core", value: 129, h: 17033},
    {key: 130, text: "AMD Ryzen 9 3900XT 12-Core", value: 130, h: 16996},
    {key: 131, text: "Intel Xeon CPU E5-2673 v4 @ 2.30GHz", value: 131, h: 16912},
    {key: 132, text: "Intel Xeon Gold 5220 CPU @ 2.20GHz", value: 132, h: 16833},
    {key: 133, text: "Intel Xeon CPU E5-2697 v4 @ 2.30GHz", value: 133, h: 16828},
    {key: 134, text: "Intel Xeon CPU E5-2686 v4 @ 2.30GHz", value: 134, h: 16814},
    {key: 135, text: "ARM Neoverse-V1", value: 135, h: 16806},
    {key: 136, text: "Intel Xeon Gold 6140 CPU @ 2.30GHz", value: 136, h: 16712},
    {key: 137, text: "Intel Xeon Silver 4216 CPU @ 2.10GHz", value: 137, h: 16666},
    {key: 138, text: "Intel Xeon CPU E5-2686 v3 @ 2.00GHz", value: 138, h: 16568},
    {key: 139, text: "Intel Xeon Gold 6240 CPU @ 2.60GHz", value: 139, h: 16563},
    {key: 140, text: "Intel Xeon Gold 6132 CPU @ 2.60GHz", value: 140, h: 16407},
    {key: 141, text: "Intel Xeon Gold 6326 CPU @ 2.90GHz", value: 141, h: 16391},
    {key: 142, text: "Intel Xeon CPU E5-2697R v4 @ 2.30GHz", value: 142, h: 16053},
    {key: 143, text: "13th Gen Intel Core i9-13900KS", value: 143, h: 15876},
    {key: 144, text: "Intel Xeon CPU E7-4880 v2 @ 2.50GHz", value: 144, h: 15874},
    {key: 145, text: "Intel Xeon Gold 6254 CPU @ 3.10GHz", value: 145, h: 15776},
    {key: 146, text: "13th Gen Intel Core i9-13900KF", value: 146, h: 15753},
    {key: 147, text: "Intel Xeon Platinum 8251 CPU @ 3.80GHz", value: 147, h: 15728},
    {key: 148, text: "AMD Ryzen 9 7900X3D 12-Core", value: 148, h: 15718},
    {key: 149, text: "Intel Xeon CPU E5-2698B v3 @ 2.00GHz", value: 149, h: 15503},
    {key: 150, text: "Intel Xeon Gold 6154 CPU @ 3.00GHz", value: 150, h: 15477},
    {key: 151, text: "Intel Xeon Gold 6146 CPU @ 3.20GHz", value: 151, h: 15457},
    {key: 152, text: "Intel Xeon Platinum 8362 CPU @ 2.80GHz", value: 152, h: 15437},
    {key: 153, text: "Intel Xeon Gold 6136 CPU @ 3.00GHz", value: 153, h: 15367},
    {key: 154, text: "Intel Xeon W-3275M CPU @ 2.50GHz", value: 154, h: 15357},
    {key: 155, text: "Intel Xeon CPU E5-2697A v4 @ 2.60GHz", value: 155, h: 15351},
    {key: 156, text: "AMD Ryzen 9 3900 12-Core", value: 156, h: 15238},
    {key: 157, text: "Intel Xeon CPU E5-2698 v3 @ 2.30GHz", value: 157, h: 15189},
    {key: 158, text: "Intel Xeon CPU E5-2695 v4 @ 2.10GHz", value: 158, h: 15039},
    {key: 159, text: "AMD EPYC 7401P 24-Core", value: 159, h: 14972},
    {key: 160, text: "AMD EPYC 7302P 16-Core", value: 160, h: 14794},
    {key: 161, text: "Intel Xeon Gold 6246 CPU @ 3.30GHz", value: 161, h: 14667},
    {key: 162, text: "Intel Xeon CPU E5-2680 v4 @ 2.40GHz", value: 162, h: 14540},
    {key: 163, text: "Intel Xeon Gold 6126 CPU @ 2.60GHz", value: 163, h: 14508},
    {key: 164, text: "Intel Xeon Gold 5120 CPU @ 2.20GHz", value: 164, h: 14505},
    {key: 165, text: "Intel Xeon CPU E5-2683 v4 @ 2.10GHz", value: 165, h: 14480},
    {key: 166, text: "Intel Xeon CPU E5-2690 v4 @ 2.60GHz", value: 166, h: 14451},
    {key: 167, text: "Intel Core i9-7980XE CPU @ 2.60GHz", value: 167, h: 13971},
    {key: 168, text: "13th Gen Intel Core i7-13700K", value: 168, h: 13943},
    {key: 169, text: "Intel Xeon Silver 4316 CPU @ 2.30GHz", value: 169, h: 13862},
    {key: 170, text: "Intel Xeon CPU E5-2682 v4 @ 2.50GHz", value: 170, h: 13845},
    {key: 171, text: "13th Gen Intel Core i9-13900K", value: 171, h: 13772},
    {key: 172, text: "ARM Neoverse-N1", value: 172, h: 13753},
    {key: 173, text: "Intel Xeon CPU E5-2697 v3 @ 2.60GHz", value: 173, h: 13730},
    {key: 174, text: "Intel Xeon CPU E5-4620 v2 @ 2.60GHz", value: 174, h: 13600},
    {key: 175, text: "AMD Ryzen Threadripper PRO 3945WX 12-Cores", value: 175, h: 13588},
    {key: 176, text: "Intel Xeon CPU E5-2683 v3 @ 2.00GHz", value: 176, h: 13438},
    {key: 177, text: "Intel Xeon Gold 6226 CPU @ 2.70GHz", value: 177, h: 13438},
    {key: 178, text: "Intel Xeon Gold 6268CL CPU @ 2.80GHz", value: 178, h: 13250},
    {key: 179, text: "Intel Xeon CPU E5-4627 v2 @ 3.30GHz", value: 179, h: 13200},
    {key: 180, text: "Intel Xeon Gold 6212U CPU @ 2.40GHz", value: 180, h: 13195},
    {key: 181, text: "AMD Ryzen 7 7700X 8-Core", value: 181, h: 13109},
    {key: 182, text: "Intel Xeon CPU E5-2678 v3 @ 2.50GHz", value: 182, h: 12938},
    {key: 183, text: "Intel Core i9-10980XE CPU @ 3.00GHz", value: 183, h: 12875},
    {key: 184, text: "Intel Xeon CPU E5-2670 v3 @ 2.30GHz", value: 184, h: 12741},
    {key: 185, text: "AMD Ryzen 7 7800X3D 8-Core", value: 185, h: 12615},
    {key: 186, text: "Intel Xeon CPU E5-4650 0 @ 2.70GHz", value: 186, h: 12562},
    {key: 187, text: "Intel Xeon CPU E5-2690 v3 @ 2.60GHz", value: 187, h: 12561},
    {key: 188, text: "Intel Xeon CPU E5-2658 v4 @ 2.30GHz", value: 188, h: 12548},
    {key: 189, text: "Intel Xeon CPU E5-2695 v3 @ 2.30GHz", value: 189, h: 12500},
    {key: 190, text: "Intel Xeon Silver 4214R CPU @ 2.40GHz", value: 190, h: 12496},
    {key: 191, text: "Intel Xeon CPU E5-2687W v4 @ 3.00GHz", value: 191, h: 12493},
    {key: 192, text: "Intel Xeon Silver 4214 CPU @ 2.20GHz", value: 192, h: 12451},
    {key: 193, text: "AMD Ryzen 7 5800X 8-Core", value: 193, h: 12445},
    {key: 194, text: "Intel Xeon Gold 5118 CPU @ 2.30GHz", value: 194, h: 12420},
    {key: 195, text: "AMD Ryzen 7 7700 8-Core", value: 195, h: 12400},
    {key: 196, text: "AMD EPYC 7351P 16-Core", value: 196, h: 12260},
    {key: 197, text: "Intel Xeon CPU E5-2660 v4 @ 2.00GHz", value: 197, h: 12142},
    {key: 198, text: "12th Gen Intel Core i9-12900KS", value: 198, h: 12111},
    {key: 199, text: "Intel Xeon Gold 6256 CPU @ 3.60GHz", value: 199, h: 12072},
    {key: 200, text: "AMD Ryzen 7 3700X 8-Core", value: 200, h: 12033},
    {key: 201, text: "13th Gen Intel Core i7-13700KF", value: 201, h: 11846},
    {key: 202, text: "Intel Xeon CPU E5-2680 v3 @ 2.50GHz", value: 202, h: 11796},
    {key: 203, text: "12th Gen Intel Core i9-12900K", value: 203, h: 11695},
    {key: 204, text: "AMD Ryzen Threadripper 1950X 16-Core", value: 204, h: 11622},
    {key: 205, text: "Intel Xeon Gold 6210U CPU @ 2.50GHz", value: 205, h: 11612},
    {key: 206, text: "AMD Ryzen 7 3800X 8-Core", value: 206, h: 11390},
    {key: 207, text: "Intel Xeon CPU E5-4640 0 @ 2.40GHz", value: 207, h: 11381},
    {key: 208, text: "AMD Ryzen 9 5900 12-Core", value: 208, h: 11277},
    {key: 209, text: "AMD Ryzen 7 5700X 8-Core", value: 209, h: 11136},
    {key: 210, text: "Intel Xeon Gold 5215 CPU @ 2.50GHz", value: 210, h: 11015},
    {key: 211, text: "AMD Ryzen 7 3800XT 8-Core", value: 211, h: 11010},
    {key: 212, text: "Intel Xeon CPU E5-2673 v3 @ 2.40GHz", value: 212, h: 11006},
    {key: 213, text: "Intel Xeon Gold 6244 CPU @ 3.60GHz", value: 213, h: 10943},
    {key: 214, text: "13th Gen Intel Core i5-13600KF", value: 214, h: 10931},
    {key: 215, text: "AMD Ryzen 7 PRO 3700 8-Core", value: 215, h: 10916},
    {key: 216, text: "AMD Ryzen Threadripper 2950X 16-Core", value: 216, h: 10819},
    {key: 217, text: "Intel Xeon Platinum 8252C CPU @ 3.80GHz", value: 217, h: 10811},
    {key: 218, text: "Intel Xeon CPU E5-2666 v3 @ 2.90GHz", value: 218, h: 10811},
    {key: 219, text: "Intel Xeon CPU E5-2650 v4 @ 2.20GHz", value: 219, h: 10808},
    {key: 220, text: "Intel Xeon Silver 4210R CPU @ 2.40GHz", value: 220, h: 10789},
    {key: 221, text: "Intel Xeon CPU E5-2697 v2 @ 2.70GHz", value: 221, h: 10752},
    {key: 222, text: "Intel Xeon Gold 6234 CPU @ 3.30GHz", value: 222, h: 10643},
    {key: 223, text: "Intel Xeon CPU E5-2658A v3 @ 2.20GHz", value: 223, h: 10642},
    {key: 224, text: "Intel Xeon CPU E7- 4860 @ 2.27GHz", value: 224, h: 10585},
    {key: 225, text: "Intel Xeon Platinum P-8136 CPU @ 2.00GHz", value: 225, h: 10523},
    {key: 226, text: "AMD Ryzen 7 5800X3D 8-Core", value: 226, h: 10516},
    {key: 227, text: "Intel Xeon CPU E5-2696 v2 @ 2.50GHz", value: 227, h: 10408},
    {key: 228, text: "Intel Xeon Silver 4210 CPU @ 2.20GHz", value: 228, h: 10346},
    {key: 229, text: "Intel Xeon CPU E5-2650L v4 @ 1.70GHz", value: 229, h: 10331},
    {key: 230, text: "Intel Xeon CPU E5-2680 v2 @ 2.80GHz", value: 230, h: 10319},
    {key: 231, text: "Intel Xeon Silver 4116 CPU @ 2.10GHz", value: 231, h: 10298},
    {key: 232, text: "13th Gen Intel Core i9-13900HX", value: 232, h: 10294},
    {key: 233, text: "AMD EPYC 7281 16-Core", value: 233, h: 10283},
    {key: 234, text: "AMD Ryzen 7 5800 8-Core", value: 234, h: 10274},
    {key: 235, text: "AMD Ryzen Threadripper 1920X 12-Core", value: 235, h: 10273},
    {key: 236, text: "Intel Xeon Gold 6134 CPU @ 3.20GHz", value: 236, h: 10225},
    {key: 237, text: "AMD Ryzen 5 7600X 6-Core", value: 237, h: 10180},
    {key: 238, text: "Intel Xeon Platinum 8163 CPU @ 2.50GHz", value: 238, h: 10175},
    {key: 239, text: "Intel Core i9-10920X CPU @ 3.50GHz", value: 239, h: 10143},
    {key: 240, text: "12th Gen Intel Core i7-12700KF", value: 240, h: 10077},
    {key: 241, text: "Intel Core i9-10900K CPU @ 3.70GHz", value: 241, h: 10024},
    {key: 242, text: "Intel Xeon CPU E5-2687W v3 @ 3.10GHz", value: 242, h: 9990},
    {key: 243, text: "ARMv8", value: 243, h: 9955},
    {key: 244, text: "Intel Core i9-7940X CPU @ 3.10GHz", value: 244, h: 9890},
    {key: 245, text: "Intel Xeon Gold 5115 CPU @ 2.40GHz", value: 245, h: 9884},
    {key: 246, text: "Intel Core i9-9960X CPU @ 3.10GHz", value: 246, h: 9841},
    {key: 247, text: "12th Gen Intel Core i7-12700K", value: 247, h: 9777},
    {key: 248, text: "13th Gen Intel Core i9-13900", value: 248, h: 9770},
    {key: 249, text: "Intel Xeon CPU E5-2660 v3 @ 2.60GHz", value: 249, h: 9738},
    {key: 250, text: "Intel Core i9-10900KF CPU @ 3.70GHz", value: 250, h: 9698},
    {key: 251, text: "Intel Core i9-10940X CPU @ 3.30GHz", value: 251, h: 9689},
    {key: 252, text: "13th Gen Intel Core i5-13600K", value: 252, h: 9675},
    {key: 253, text: "Intel Xeon Silver 4114 CPU @ 2.20GHz", value: 253, h: 9674},
    {key: 254, text: "Intel Xeon CPU E5-2690 v2 @ 3.00GHz", value: 254, h: 9654},
    {key: 255, text: "Intel Xeon CPU E5-2695 v2 @ 2.40GHz", value: 255, h: 9619},
    {key: 256, text: "Intel Xeon Silver 4215R CPU @ 3.20GHz", value: 256, h: 9531},
    {key: 257, text: "Intel Xeon CPU E5-2667 v4 @ 3.20GHz", value: 257, h: 9501},
    {key: 258, text: "Intel Xeon Gold 6278C CPU @ 2.60GHz", value: 258, h: 9435},
    {key: 259, text: "Intel Xeon CPU E5-2667 v3 @ 3.20GHz", value: 259, h: 9392},
    {key: 260, text: "Intel Xeon CPU E5-4669 v3 @ 2.10GHz", value: 260, h: 9349},
    {key: 261, text: "Intel Xeon CPU E5-2667 v2 @ 3.30GHz", value: 261, h: 9321},
    {key: 262, text: "Intel Xeon CPU E5-2650 v3 @ 2.30GHz", value: 262, h: 9319},
    {key: 263, text: "Intel Xeon CPU E5-2699C v4 @ 2.20GHz", value: 263, h: 9277},
    {key: 264, text: "Intel Xeon CPU E5-2640 v4 @ 2.40GHz", value: 264, h: 9268},
    {key: 265, text: "AMD Ryzen Threadripper 2920X 12-Core", value: 265, h: 9175},
    {key: 266, text: "Intel Xeon CPU E5-2630 v4 @ 2.20GHz", value: 266, h: 9128},
    {key: 267, text: "Intel Xeon CPU E7- 8870 @ 2.40GHz", value: 267, h: 9113},
    {key: 268, text: "Intel Xeon Silver 4215 CPU @ 2.50GHz", value: 268, h: 9100},
    {key: 269, text: "AMD Ryzen 5 5600X 6-Core", value: 269, h: 9100},
    {key: 270, text: "Intel Xeon CPU E5-4620 0 @ 2.20GHz", value: 270, h: 9063},
    {key: 271, text: "Intel Xeon CPU E5-2687W v2 @ 3.40GHz", value: 271, h: 9038},
    {key: 272, text: "12th Gen Intel Core i9-12900F", value: 272, h: 8984},
    {key: 273, text: "Intel Xeon CPU E7- 4850 @ 2.00GHz", value: 273, h: 8937},
    {key: 274, text: "Intel Xeon CPU E5-2650L v3 @ 1.80GHz", value: 274, h: 8934},
    {key: 275, text: "12th Gen Intel Core i9-12900KF", value: 275, h: 8928},
    {key: 276, text: "Intel Xeon CPU E5-2630 v3 @ 2.40GHz", value: 276, h: 8921},
    {key: 277, text: "Intel Xeon W-2295 CPU @ 3.00GHz", value: 277, h: 8904},
    {key: 278, text: "Intel Core i9-10850K CPU @ 3.60GHz", value: 278, h: 8860},
    {key: 279, text: "Intel Core i9-9940X CPU @ 3.30GHz", value: 279, h: 8851},
    {key: 280, text: "AMD Ryzen 5 3600 6-Core", value: 280, h: 8843},
    {key: 281, text: "Intel Xeon CPU E5-2648L v4 @ 1.80GHz", value: 281, h: 8731},
    {key: 282, text: "Intel Core i9-7900X CPU @ 3.30GHz", value: 282, h: 8699},
    {key: 283, text: "Intel Xeon CPU E5-2670 v2 @ 2.50GHz", value: 283, h: 8659},
    {key: 284, text: "AMD Ryzen 5 7600 6-Core", value: 284, h: 8447},
    {key: 285, text: "AMD Ryzen 5 5600 6-Core", value: 285, h: 8347},
    {key: 286, text: "Intel Core i9-7960X CPU @ 2.80GHz", value: 286, h: 8263},
    {key: 287, text: "Intel Xeon W-2170B CPU @ 2.50GHz", value: 287, h: 8223},
    {key: 288, text: "Intel Xeon W-2175 CPU @ 2.50GHz", value: 288, h: 8169},
    {key: 289, text: "Intel Core i9-9900K CPU @ 3.60GHz", value: 289, h: 8146},
    {key: 290, text: "AMD Ryzen 5 3600XT 6-Core", value: 290, h: 8145},
    {key: 291, text: "Intel Xeon Silver 4208 CPU @ 2.10GHz", value: 291, h: 8132},
    {key: 292, text: "AMD Ryzen 5 3600X 6-Core", value: 292, h: 8126},
    {key: 293, text: "Intel Core i9-7920X CPU @ 2.90GHz", value: 293, h: 8117},
    {key: 294, text: "Intel Xeon CPU E5-2640 v3 @ 2.60GHz", value: 294, h: 8097},
    {key: 295, text: "12th Gen Intel Core i5-12600K", value: 295, h: 8040},
    {key: 296, text: "12th Gen Intel Core i7-12700", value: 296, h: 7966},
    {key: 297, text: "11th Gen Intel Core i9-11900K @ 3.50GHz", value: 297, h: 7876},
    {key: 298, text: "Apple M2 Ultra", value: 298, h: 7859},
    {key: 299, text: "Intel Xeon CPU E5-2660 v2 @ 2.20GHz", value: 299, h: 7833},
    {key: 300, text: "Intel Xeon CPU E5-2470 v2 @ 2.40GHz", value: 300, h: 7756},
    {key: 301, text: "Intel Xeon CPU E5-2630L v3 @ 1.80GHz", value: 301, h: 7737},
    {key: 302, text: "Intel Xeon CPU E5-2658 v2 @ 2.40GHz", value: 302, h: 7712},
    {key: 303, text: "Intel Core i9-9900X CPU @ 3.50GHz", value: 303, h: 7678},
    {key: 304, text: "11th Gen Intel Core i7-11700KF @ 3.60GHz", value: 304, h: 7675},
    {key: 305, text: "Intel Core i7-10700K CPU @ 3.80GHz", value: 305, h: 7645},
    {key: 306, text: "Intel Xeon CPU E5-2687W 0 @ 3.10GHz", value: 306, h: 7640},
    {key: 307, text: "Intel Xeon Silver 4110 CPU @ 2.10GHz", value: 307, h: 7573},
    {key: 308, text: "AMD Ryzen 7 5700G with Radeon Graphics", value: 308, h: 7556},
    {key: 309, text: "Intel Core i9-9900KS CPU @ 4.00GHz", value: 309, h: 7459},
    {key: 310, text: "12th Gen Intel Core i5-12600KF", value: 310, h: 7451},
    {key: 311, text: "Intel Xeon CPU E5-2690 0 @ 2.90GHz", value: 311, h: 7431},
    {key: 312, text: "Intel Xeon CPU E5-2643 v3 @ 3.40GHz", value: 312, h: 7386},
    {key: 313, text: "AMD EPYC 7252 8-Core", value: 313, h: 7375},
    {key: 314, text: "Intel Xeon CPU E5-2651 v2 @ 1.80GHz", value: 314, h: 7349},
    {key: 315, text: "Intel Xeon CPU E5-2650 v2 @ 2.60GHz", value: 315, h: 7278},
    {key: 316, text: "12th Gen Intel Core i9-12900", value: 316, h: 7265},
    {key: 317, text: "Intel Xeon CPU E5-2689 0 @ 2.60GHz", value: 317, h: 7258},
    {key: 318, text: "Intel Xeon W-3235 CPU @ 3.30GHz", value: 318, h: 7247},
    {key: 319, text: "Intel Xeon CPU E5-2643 v2 @ 3.50GHz", value: 319, h: 7209},
    {key: 320, text: "11th Gen Intel Core i7-11700K @ 3.60GHz", value: 320, h: 7161},
    {key: 321, text: "Intel Core i9-10900X CPU @ 3.70GHz", value: 321, h: 7160},
    {key: 322, text: "Intel Core i7-6950X CPU @ 3.00GHz", value: 322, h: 7149},
    {key: 323, text: "Intel Xeon CPU E5-2620 v4 @ 2.10GHz", value: 323, h: 7107},
    {key: 324, text: "13th Gen Intel Core i5-13500", value: 324, h: 7090},
    {key: 325, text: "Intel Xeon CPU E5-2680 0 @ 2.70GHz", value: 325, h: 6996},
    {key: 326, text: "AMD Ryzen 7 2700 Eight-Core", value: 326, h: 6991},
    {key: 327, text: "Intel Xeon CPU E5-2670 0 @ 2.60GHz", value: 327, h: 6963},
    {key: 328, text: "Intel Xeon CPU E5-2643 v4 @ 3.40GHz", value: 328, h: 6959},
    {key: 329, text: "12th Gen Intel Core i7-12700F", value: 329, h: 6937},
    {key: 330, text: "13th Gen Intel Core i9-13980HX", value: 330, h: 6830},
    {key: 331, text: "Intel Core i9-10900F CPU @ 2.80GHz", value: 331, h: 6829},
    {key: 332, text: "Intel Core i9-9900KF CPU @ 3.60GHz", value: 332, h: 6763},
    {key: 333, text: "AMD Ryzen 7 PRO 2700 Eight-Core", value: 333, h: 6730},
    {key: 334, text: "AMD Ryzen 7 2700X Eight-Core", value: 334, h: 6702},
    {key: 335, text: "AMD Ryzen 5 5600G with Radeon Graphics", value: 335, h: 6699},
    {key: 336, text: "Intel Xeon Gold 5217 CPU @ 3.00GHz", value: 336, h: 6698},
    {key: 337, text: "11th Gen Intel Core i9-11980HK @ 2.60GHz", value: 337, h: 6675},
    {key: 338, text: "Intel Core i7-10700F CPU @ 2.90GHz", value: 338, h: 6598},
    {key: 339, text: "Intel Core i9-9920X CPU @ 3.50GHz", value: 339, h: 6570},
    {key: 340, text: "Intel Core i9-10900 CPU @ 2.80GHz", value: 340, h: 6569},
    {key: 341, text: "AMD Ryzen 7 1800X Eight-Core", value: 341, h: 6567},
    {key: 342, text: "Intel Core i9-9820X CPU @ 3.30GHz", value: 342, h: 6551},
    {key: 343, text: "12th Gen Intel Core i5-12400F", value: 343, h: 6548},
    {key: 344, text: "Intel Xeon CPU E5-2620 v3 @ 2.40GHz", value: 344, h: 6531},
    {key: 345, text: "Intel Core i7-10700KF CPU @ 3.80GHz", value: 345, h: 6517},
    {key: 346, text: "Apple M1 Ultra", value: 346, h: 6466},
    {key: 347, text: "AMD Ryzen 5 5500", value: 347, h: 6388},
    {key: 348, text: "Intel Xeon W-2155 CPU @ 3.30GHz", value: 348, h: 6381},
    {key: 349, text: "11th Gen Intel Core i9-11900 @ 2.50GHz", value: 349, h: 6326},
    {key: 350, text: "12th Gen Intel Core i5-12500", value: 350, h: 6300},
    {key: 351, text: "11th Gen Intel Core i7-11700 @ 2.50GHz", value: 351, h: 6284},
    {key: 352, text: "Intel Xeon CPU E5-2665 0 @ 2.40GHz", value: 352, h: 6282},
    {key: 353, text: "AMD Ryzen 7 1700 Eight-Core", value: 353, h: 6243},
    {key: 354, text: "12th Gen Intel Core i5-12400", value: 354, h: 6238},
    {key: 355, text: "AMD Ryzen 7 1700X Eight-Core", value: 355, h: 6198},
    {key: 356, text: "Intel Xeon CPU E5-2660 0 @ 2.20GHz", value: 356, h: 6190},
    {key: 357, text: "Intel Xeon W-1290P CPU @ 3.70GHz", value: 357, h: 6186},
    {key: 358, text: "Intel Core i7-10700 CPU @ 2.90GHz", value: 358, h: 6168},
    {key: 359, text: "Intel Core i7-7820X CPU @ 3.60GHz", value: 359, h: 6145},
    {key: 360, text: "12th Gen Intel Core i7-1280P", value: 360, h: 6132},
    {key: 361, text: "Intel Xeon CPU E5-2470 0 @ 2.30GHz", value: 361, h: 6128},
    {key: 362, text: "Intel Xeon CPU E5-2676 v3 @ 2.40GHz", value: 362, h: 6118},
    {key: 363, text: "12th Gen Intel Core i7-12700H", value: 363, h: 6084},
    {key: 364, text: "AMD Ryzen 9 5900HX with Radeon Graphics", value: 364, h: 6045},
    {key: 365, text: "13th Gen Intel Core i5-13400", value: 365, h: 6020},
    {key: 366, text: "Intel Xeon CPU E5-2650L v2 @ 1.70GHz", value: 366, h: 6015},
    {key: 367, text: "13th Gen Intel Core i5-13400F", value: 367, h: 5974},
    {key: 368, text: "12th Gen Intel Core i9-12900H", value: 368, h: 5967},
    {key: 369, text: "Intel Core i7-8700K CPU @ 3.70GHz", value: 369, h: 5894},
    {key: 370, text: "Intel Xeon CPU E5-2640 v2 @ 2.00GHz", value: 370, h: 5850},
    {key: 371, text: "Intel Xeon W-2265 CPU @ 3.50GHz", value: 371, h: 5785},
    {key: 372, text: "Intel Xeon CPU E5-1680 v2 @ 3.00GHz", value: 372, h: 5784},
    {key: 373, text: "11th Gen Intel Core i7-11700F @ 2.50GHz", value: 373, h: 5771},
    {key: 374, text: "Intel Xeon W-1270P CPU @ 3.80GHz", value: 374, h: 5767},
    {key: 375, text: "AMD Ryzen 5 2600X Six-Core", value: 375, h: 5764},
    {key: 376, text: "AMD Ryzen 7 7735HS with Radeon Graphics", value: 376, h: 5757},
    {key: 377, text: "Intel Xeon E-2288G CPU @ 3.70GHz", value: 377, h: 5715},
    {key: 378, text: "Intel Core i5-10600K CPU @ 4.10GHz", value: 378, h: 5680},
    {key: 379, text: "AMD Ryzen 9 5900HS with Radeon Graphics", value: 379, h: 5647},
    {key: 380, text: "11th Gen Intel Core i9-11900KF @ 3.50GHz", value: 380, h: 5595},
    {key: 381, text: "AMD Ryzen 3 3300X 4-Core", value: 381, h: 5593},
    {key: 382, text: "Intel Xeon CPU E5-2440 v2 @ 1.90GHz", value: 382, h: 5533},
    {key: 383, text: "Intel Xeon CPU E5-2650 0 @ 2.00GHz", value: 383, h: 5495},
    {key: 384, text: "AMD Ryzen Threadripper 1900X 8-Core", value: 384, h: 5490},
    {key: 385, text: "AMD Ryzen 5 2600 Six-Core", value: 385, h: 5480},
    {key: 386, text: "Intel Xeon CPU E5-2630 v2 @ 2.60GHz", value: 386, h: 5473},
    {key: 387, text: "Intel Xeon E-2278G CPU @ 3.40GHz", value: 387, h: 5453},
    {key: 388, text: "AMD Ryzen 5 1600 Six-Core", value: 388, h: 5379},
    {key: 389, text: "13th Gen Intel Core i7-1370P", value: 389, h: 5376},
    {key: 390, text: "Intel Xeon Silver 4310 CPU @ 2.10GHz", value: 390, h: 5342},
    {key: 391, text: "Intel Xeon CPU E5-2667 0 @ 2.90GHz", value: 391, h: 5320},
    {key: 392, text: "AMD EPYC 7251 8-Core", value: 392, h: 5264},
    {key: 393, text: "11th Gen Intel Core i5-11600KF @ 3.90GHz", value: 393, h: 5255},
    {key: 394, text: "Intel Core i7-5960X CPU @ 3.00GHz", value: 394, h: 5252},
    {key: 395, text: "12th Gen Intel Core i7-12650H", value: 395, h: 5239},
    {key: 396, text: "AMD Ryzen 5 PRO 5650G with Radeon Graphics", value: 396, h: 5194},
    {key: 397, text: "Intel Core i9-9900 CPU @ 3.10GHz", value: 397, h: 5160},
    {key: 398, text: "Intel Xeon W-3223 CPU @ 3.50GHz", value: 398, h: 5157},
    {key: 399, text: "Intel Xeon CPU E5-2430 v2 @ 2.50GHz", value: 399, h: 5099},
    {key: 400, text: "Intel Core i7-9700K CPU @ 3.60GHz", value: 400, h: 5076},
    {key: 401, text: "AMD Ryzen 3 3100 4-Core", value: 401, h: 5068},
    {key: 402, text: "AMD Ryzen 9 6900HX with Radeon Graphics", value: 402, h: 5065},
    {key: 403, text: "Intel Xeon CPU E5-2609 v4 @ 1.70GHz", value: 403, h: 5051},
    {key: 404, text: "AMD Ryzen 9 5980HX with Radeon Graphics", value: 404, h: 5034},
    {key: 405, text: "Intel Core i9-10980HK CPU @ 2.40GHz", value: 405, h: 5032},
    {key: 406, text: "11th Gen Intel Core i5-11600K @ 3.90GHz", value: 406, h: 4999},
    {key: 407, text: "Intel Core i7-8700 CPU @ 3.20GHz", value: 407, h: 4997},
    {key: 408, text: "Intel Xeon CPU E5-2630L v2 @ 2.40GHz", value: 408, h: 4982},
    {key: 409, text: "Intel Core i5-10600KF CPU @ 4.10GHz", value: 409, h: 4961},
    {key: 410, text: "AMD Ryzen 7 PRO 1700X Eight-Core", value: 410, h: 4954},
    {key: 411, text: "AMD Ryzen 5 3500 6-Core", value: 411, h: 4940},
    {key: 412, text: "AMD Ryzen 5 3500X 6-Core", value: 412, h: 4882},
    {key: 413, text: "AMD Ryzen 7 5800HS with Radeon Graphics", value: 413, h: 4853},
    {key: 414, text: "11th Gen Intel Core i5-11400F @ 2.60GHz", value: 414, h: 4850},
    {key: 415, text: "11th Gen Intel Core i5-11400 @ 2.60GHz", value: 415, h: 4830},
    {key: 416, text: "Intel Xeon CPU E5-2649 v3 @ 2.30GHz", value: 416, h: 4803},
    {key: 417, text: "Intel Xeon CPU E5-2640 0 @ 2.50GHz", value: 417, h: 4756},
    {key: 418, text: "Apple M2 Max", value: 418, h: 4748},
    {key: 419, text: "Intel Core i5-10500 CPU @ 3.10GHz", value: 419, h: 4723},
    {key: 420, text: "AMD Ryzen 5 1600X Six-Core", value: 420, h: 4701},
    {key: 421, text: "Intel Xeon CPU E5-2450L 0 @ 1.80GHz", value: 421, h: 4668},
    {key: 422, text: "AMD Ryzen 7 PRO 5750G with Radeon Graphics", value: 422, h: 4632},
    {key: 423, text: "Intel Xeon CPU E5-2620 v2 @ 2.10GHz", value: 423, h: 4631},
    {key: 424, text: "Intel Core i7-6900K CPU @ 3.20GHz", value: 424, h: 4629},
    {key: 425, text: "Intel Xeon CPU E5-2450 v2 @ 2.50GHz", value: 425, h: 4606},
    {key: 426, text: "Intel Xeon Platinum 8255C CPU @ 2.50GHz", value: 426, h: 4570},
    {key: 427, text: "Intel Xeon CPU E5-2637 v2 @ 3.50GHz", value: 427, h: 4568},
    {key: 428, text: "Intel Xeon CPU E5-2623 v3 @ 3.00GHz", value: 428, h: 4567},
    {key: 429, text: "AMD EPYC 3251 8-Core", value: 429, h: 4562},
    {key: 430, text: "Intel Core i7-5820K CPU @ 3.30GHz", value: 430, h: 4562},
    {key: 431, text: "Intel Core i7-9700 CPU @ 3.00GHz", value: 431, h: 4507},
    {key: 432, text: "Intel Xeon CPU E5-2630 0 @ 2.30GHz", value: 432, h: 4492},
    {key: 433, text: "11th Gen Intel Core i9-11900F @ 2.50GHz", value: 433, h: 4468},
    {key: 434, text: "Intel Core i5-10400 CPU @ 2.90GHz", value: 434, h: 4447},
    {key: 435, text: "Intel Xeon CPU E5-1660 v4 @ 3.20GHz", value: 435, h: 4446},
    {key: 436, text: "Intel Core i7-9700F CPU @ 3.00GHz", value: 436, h: 4422},
    {key: 437, text: "Intel Xeon CPU E5-2637 v3 @ 3.50GHz", value: 437, h: 4398},
    {key: 438, text: "AMD Ryzen 7 PRO 6850U with Radeon Graphics", value: 438, h: 4394},
    {key: 439, text: "Intel Xeon D-2166NT CPU @ 2.00GHz", value: 439, h: 4392},
    {key: 440, text: "Intel Xeon E-2276G CPU @ 3.80GHz", value: 440, h: 4384},
    {key: 441, text: "12th Gen Intel Core i7-12800H", value: 441, h: 4376},
    {key: 442, text: "Intel Core i7-8086K CPU @ 4.00GHz", value: 442, h: 4368},
    {key: 443, text: "Intel Xeon CPU E5-2430L v2 @ 2.40GHz", value: 443, h: 4341},
    {key: 444, text: "Intel Xeon CPU E5-2650L 0 @ 1.80GHz", value: 444, h: 4332},
    {key: 445, text: "12th Gen Intel Core i5-12500H", value: 445, h: 4290},
    {key: 446, text: "AMD Ryzen 9 5980HS with Radeon Graphics", value: 446, h: 4286},
    {key: 447, text: "Intel Xeon CPU E5-2637 v4 @ 3.50GHz", value: 447, h: 4270},
    {key: 448, text: "12th Gen Intel Core i3-12100", value: 448, h: 4262},
    {key: 449, text: "Intel Core i5-10400F CPU @ 2.90GHz", value: 449, h: 4255},
    {key: 450, text: "AMD Ryzen 7 PRO 1700 Eight-Core", value: 450, h: 4219},
    {key: 451, text: "Intel Xeon CPU E5-2430 0 @ 2.20GHz", value: 451, h: 4208},
    {key: 452, text: "Intel Xeon E-2286G CPU @ 4.00GHz", value: 452, h: 4178},
    {key: 453, text: "AMD Ryzen 7 PRO 5850U with Radeon Graphics", value: 453, h: 4173},
    {key: 454, text: "Intel Core i7-5930K CPU @ 3.50GHz", value: 454, h: 4165},
    {key: 455, text: "Intel Core i9-9980HK CPU @ 2.40GHz", value: 455, h: 4158},
    {key: 456, text: "Intel Xeon E-2146G CPU @ 3.50GHz", value: 456, h: 4134},
    {key: 457, text: "Intel Xeon CPU E5-1660 v3 @ 3.00GHz", value: 457, h: 4125},
    {key: 458, text: "Intel Core i7-6800K CPU @ 3.40GHz", value: 458, h: 4110},
    {key: 459, text: "Intel Xeon E-2176G CPU @ 3.70GHz", value: 459, h: 4109},
    {key: 460, text: "Intel Core i7-9700KF CPU @ 3.60GHz", value: 460, h: 4103},
    {key: 461, text: "Intel Xeon Silver 4108 CPU @ 1.80GHz", value: 461, h: 4096},
    {key: 462, text: "11th Gen Intel Core i5-11600 @ 2.80GHz", value: 462, h: 4092},
    {key: 463, text: "Apple M2 Pro", value: 463, h: 4080},
    {key: 464, text: "Intel Xeon CPU E5-2620 0 @ 2.00GHz", value: 464, h: 4074},
    {key: 465, text: "Intel Xeon W-2245 CPU @ 3.90GHz", value: 465, h: 4072},
    {key: 466, text: "11th Gen Intel Core i5-11500 @ 2.70GHz", value: 466, h: 4036},
    {key: 467, text: "Intel Xeon E-2236 CPU @ 3.40GHz", value: 467, h: 4020},
    {key: 468, text: "Intel Xeon E-2386G CPU @ 3.50GHz", value: 468, h: 3985},
    {key: 469, text: "Intel Xeon W-2133 CPU @ 3.60GHz", value: 469, h: 3927},
    {key: 470, text: "Intel Xeon CPU E5-2609 v3 @ 1.90GHz", value: 470, h: 3917},
    {key: 471, text: "AMD Ryzen 7 4700G with Radeon Graphics", value: 471, h: 3914},
    {key: 472, text: "12th Gen Intel Core i3-12100F", value: 472, h: 3864},
    {key: 473, text: "Intel Xeon CPU E5-1650 v3 @ 3.50GHz", value: 473, h: 3855},
    {key: 474, text: "AMD Opteron 8439 SE", value: 474, h: 3816},
    {key: 475, text: "Intel Core i5-10600 CPU @ 3.30GHz", value: 475, h: 3803},
    {key: 476, text: "Intel Xeon CPU E5-2420 0 @ 1.90GHz", value: 476, h: 3784},
    {key: 477, text: "Intel Xeon CPU E5-1650 v4 @ 3.60GHz", value: 477, h: 3770},
    {key: 478, text: "Intel Xeon CPU E5-2643 0 @ 3.30GHz", value: 478, h: 3759},
    {key: 479, text: "Intel Core i7-6850K CPU @ 3.60GHz", value: 479, h: 3741},
    {key: 480, text: "Intel Core i7-7800X CPU @ 3.50GHz", value: 480, h: 3713},
    {key: 481, text: "Intel Core i5-9600K CPU @ 3.70GHz", value: 481, h: 3712},
    {key: 482, text: "Intel Xeon CPU E5-1660 v2 @ 3.70GHz", value: 482, h: 3696},
    {key: 483, text: "Intel Xeon Silver 4109T CPU @ 2.00GHz", value: 483, h: 3684},
    {key: 484, text: "11th Gen Intel Core i7-1165G7 @ 2.80GHz", value: 484, h: 3663},
    {key: 485, text: "AMD Ryzen 5 PRO 5650GE with Radeon Graphics", value: 485, h: 3640},
    {key: 486, text: "Intel Xeon Platinum 8481C CPU @ 2.70GHz", value: 486, h: 3635},
    {key: 487, text: "11th Gen Intel Core i5-11500T @ 1.50GHz", value: 487, h: 3621},
    {key: 488, text: "Intel Core i9-8950HK CPU @ 2.90GHz", value: 488, h: 3616},
    {key: 489, text: "Intel Xeon CPU X5680 @ 3.33GHz", value: 489, h: 3596},
    {key: 490, text: "Intel Xeon CPU X5670 @ 2.93GHz", value: 490, h: 3553},
    {key: 491, text: "Intel Core i5-9600KF CPU @ 3.70GHz", value: 491, h: 3545},
    {key: 492, text: "Intel Core i7-7700K CPU @ 4.20GHz", value: 492, h: 3535},
    {key: 493, text: "Intel Xeon CPU E5-2440 0 @ 2.40GHz", value: 493, h: 3535},
    {key: 494, text: "Intel Core i5-8600K CPU @ 3.60GHz", value: 494, h: 3535},
    {key: 495, text: "Apple M1 Max", value: 495, h: 3531},
    {key: 496, text: "Apple M1 Pro", value: 496, h: 3514},
    {key: 497, text: "Intel Xeon CPU X5690 @ 3.47GHz", value: 497, h: 3510},
    {key: 498, text: "Intel Xeon CPU X5675 @ 3.07GHz", value: 498, h: 3506},
    {key: 499, text: "AMD 4700S 8-Core Desktop Kit", value: 499, h: 3499},
    {key: 500, text: "Intel Core i7-9700T CPU @ 2.00GHz", value: 500, h: 3495},
    {key: 501, text: "Intel Xeon CPU E5-2603 v3 @ 1.60GHz", value: 501, h: 3491},
    {key: 502, text: "AMD Ryzen 7 PRO 4750G with Radeon Graphics", value: 502, h: 3489},
    {key: 503, text: "Intel Xeon CPU X5660 @ 2.80GHz", value: 503, h: 3466},
    {key: 504, text: "12th Gen Intel Core i5-1240P", value: 504, h: 3462},
    {key: 505, text: "Intel Core i5-10500T CPU @ 2.30GHz", value: 505, h: 3456},
    {key: 506, text: "Intel Xeon CPU X5650 @ 2.67GHz", value: 506, h: 3434},
    {key: 507, text: "Intel Core i7-4960X CPU @ 3.60GHz", value: 507, h: 3430},
    {key: 508, text: "11th Gen Intel Core i7-11700B @ 3.20GHz", value: 508, h: 3430},
    {key: 509, text: "Intel Core i3-8350K CPU @ 4.00GHz", value: 509, h: 3425},
    {key: 510, text: "AMD Ryzen 9 6900HS with Radeon Graphics", value: 510, h: 3423},
    {key: 511, text: "Intel Core i7-8700T CPU @ 2.40GHz", value: 511, h: 3406},
    {key: 512, text: "Intel Xeon CPU X5687 @ 3.60GHz", value: 512, h: 3405},
    {key: 513, text: "AMD Ryzen 5 4500 6-Core", value: 513, h: 3402},
    {key: 514, text: "Intel Core i5-10400T CPU @ 2.00GHz", value: 514, h: 3401},
    {key: 515, text: "AMD Ryzen 5 PRO 4650G with Radeon Graphics", value: 515, h: 3376},
    {key: 516, text: "Intel Xeon CPU E5-2420 v2 @ 2.20GHz", value: 516, h: 3368},
    {key: 517, text: "Intel Core i7-6700K CPU @ 4.00GHz", value: 517, h: 3365},
    {key: 518, text: "AMD Ryzen 5 1500X Quad-Core", value: 518, h: 3342},
    {key: 519, text: "Intel Core i7-10700T CPU @ 2.00GHz", value: 519, h: 3321},
    {key: 520, text: "Intel Xeon CPU E5-1660 0 @ 3.30GHz", value: 520, h: 3308},
    {key: 521, text: "Intel Core i5-9400 CPU @ 2.90GHz", value: 521, h: 3269},
    {key: 522, text: "12th Gen Intel Core i7-1260P", value: 522, h: 3266},
    {key: 523, text: "Intel Core i7-3960X CPU @ 3.30GHz", value: 523, h: 3253},
    {key: 524, text: "Intel Xeon W-10885M CPU @ 2.40GHz", value: 524, h: 3248},
    {key: 525, text: "Intel Xeon E-2176M CPU @ 2.70GHz", value: 525, h: 3246},
    {key: 526, text: "Intel Core i7-4790K CPU @ 4.00GHz", value: 526, h: 3201},
    {key: 527, text: "Intel Core i3-10300 CPU @ 3.70GHz", value: 527, h: 3157},
    {key: 528, text: "Intel Core i7-4960HQ CPU @ 2.60GHz", value: 528, h: 3156},
    {key: 529, text: "AMD Ryzen 9 4900HS with Radeon Graphics", value: 529, h: 3152},
    {key: 530, text: "Intel Xeon CPU E5-2609 v2 @ 2.50GHz", value: 530, h: 3143},
    {key: 531, text: "Intel Core i7-3770K CPU @ 3.50GHz", value: 531, h: 3130},
    {key: 532, text: "Intel Core i7-8700B CPU @ 3.20GHz", value: 532, h: 3128},
    {key: 533, text: "Intel Core i5-9400F CPU @ 2.90GHz", value: 533, h: 3124},
    {key: 534, text: "Intel Xeon E-2274G CPU @ 4.00GHz", value: 534, h: 3102},
    {key: 535, text: "11th Gen Intel Core i7-1195G7 @ 2.90GHz", value: 535, h: 3095},
    {key: 536, text: "12th Gen Intel Core i5-12450H", value: 536, h: 3055},
    {key: 537, text: "Intel Core i7-4770K CPU @ 3.50GHz", value: 537, h: 3049},
    {key: 538, text: "Intel Core i5-8400 CPU @ 2.80GHz", value: 538, h: 3045},
    {key: 539, text: "Intel Xeon CPU E5-1650 v2 @ 3.50GHz", value: 539, h: 3044},
    {key: 540, text: "Intel Core i7-3970X CPU @ 3.50GHz", value: 540, h: 3033},
    {key: 541, text: "Intel Core i5-8500 CPU @ 3.00GHz", value: 541, h: 3010},
    {key: 542, text: "AMD Ryzen 3 1200 Quad-Core", value: 542, h: 3007},
    {key: 543, text: "11th Gen Intel Core i7-11700T @ 1.40GHz", value: 543, h: 3000},
    {key: 544, text: "Intel Xeon E-2134 CPU @ 3.50GHz", value: 544, h: 2988},
    {key: 545, text: "AMD Ryzen 5 4600G with Radeon Graphics", value: 545, h: 2985},
    {key: 546, text: "11th Gen Intel Core i5-1135G7 @ 2.40GHz", value: 546, h: 2983},
    {key: 547, text: "Intel Core i9-10900T CPU @ 1.90GHz", value: 547, h: 2970},
    {key: 548, text: "Intel Core i7-3930K CPU @ 3.20GHz", value: 548, h: 2947},
    {key: 549, text: "Intel Core i5-9500 CPU @ 3.00GHz", value: 549, h: 2936},
    {key: 550, text: "Intel Xeon E-2224 CPU @ 3.40GHz", value: 550, h: 2924},
    {key: 551, text: "Intel Core i7-7700 CPU @ 3.60GHz", value: 551, h: 2910},
    {key: 552, text: "Intel Core i7-4930K CPU @ 3.40GHz", value: 552, h: 2899},
    {key: 553, text: "Intel Xeon CPU E3-1270 v6 @ 3.80GHz", value: 553, h: 2895},
    {key: 554, text: "Intel Xeon CPU E5649 @ 2.53GHz", value: 554, h: 2890},
    {key: 555, text: "Intel Core i7-7740X CPU @ 4.30GHz", value: 555, h: 2889},
    {key: 556, text: "Intel Core i7-7820HK CPU @ 2.90GHz", value: 556, h: 2875},
    {key: 557, text: "Intel Core i7-4790 CPU @ 3.60GHz", value: 557, h: 2865},
    {key: 558, text: "AMD Ryzen 7 PRO 4750U with Radeon Graphics", value: 558, h: 2858},
    {key: 559, text: "Intel Xeon CPU E5645 @ 2.40GHz", value: 559, h: 2853},
    {key: 560, text: "Intel Xeon CPU E3-1246 v3 @ 3.50GHz", value: 560, h: 2835},
    {key: 561, text: "Intel Xeon CPU X7460 @ 2.66GHz", value: 561, h: 2829},
    {key: 562, text: "Intel Xeon E-2124 CPU @ 3.30GHz", value: 562, h: 2817},
    {key: 563, text: "Intel Core i7-6700 CPU @ 3.40GHz", value: 563, h: 2801},
    {key: 564, text: "Intel Core i5-9500T CPU @ 2.20GHz", value: 564, h: 2801},
    {key: 565, text: "Intel Xeon CPU E3-1275 v6 @ 3.80GHz", value: 565, h: 2800},
    {key: 566, text: "Intel Xeon E-2224G CPU @ 3.50GHz", value: 566, h: 2799},
    {key: 567, text: "11th Gen Intel Core i7-1185G7 @ 3.00GHz", value: 567, h: 2798},
    {key: 568, text: "Apple M2", value: 568, h: 2797},
    {key: 569, text: "Intel Xeon CPU L5640 @ 2.27GHz", value: 569, h: 2797},
    {key: 570, text: "Intel Xeon CPU E3-1240 v6 @ 3.70GHz", value: 570, h: 2791},
    {key: 571, text: "11th Gen Intel Core i5-1155G7 @ 2.50GHz", value: 571, h: 2760},
    {key: 572, text: "Intel Core i7-7820HQ CPU @ 2.90GHz", value: 572, h: 2757},
    {key: 573, text: "Intel Xeon CPU E7- 2870 @ 2.40GHz", value: 573, h: 2750},
    {key: 574, text: "Intel Xeon E-2136 CPU @ 3.30GHz", value: 574, h: 2750},
    {key: 575, text: "Intel Core i7-3770 CPU @ 3.40GHz", value: 575, h: 2728},
    {key: 576, text: "Intel Xeon CPU E5-2628L v2 @ 1.90GHz", value: 576, h: 2726},
    {key: 577, text: "AMD Ryzen 7 4800HS with Radeon Graphics", value: 577, h: 2720},
    {key: 578, text: "Intel Core i5-8600T CPU @ 2.30GHz", value: 578, h: 2719},
    {key: 579, text: "AMD Ryzen 5 PRO 4650U with Radeon Graphics", value: 579, h: 2701},
    {key: 580, text: "Intel Core i7-9750HF CPU @ 2.60GHz", value: 580, h: 2700},
    {key: 581, text: "Intel Core i7-5775C CPU @ 3.30GHz", value: 581, h: 2694},
    {key: 582, text: "Intel Xeon CPU E3-1281 v3 @ 3.70GHz", value: 582, h: 2692},
    {key: 583, text: "Intel Xeon CPU X5672 @ 3.20GHz", value: 583, h: 2683},
    {key: 584, text: "Intel Xeon CPU E5-2609 0 @ 2.40GHz", value: 584, h: 2667},
    {key: 585, text: "Intel Xeon CPU E3-1230 v6 @ 3.50GHz", value: 585, h: 2663},
    {key: 586, text: "AMD Ryzen 3 1300X Quad-Core", value: 586, h: 2661},
    {key: 587, text: "Intel Xeon CPU E3-1505M v6 @ 3.00GHz", value: 587, h: 2649},
    {key: 588, text: "Intel Xeon CPU E5640 @ 2.67GHz", value: 588, h: 2633},
    {key: 589, text: "Intel Xeon CPU E3-1271 v3 @ 3.60GHz", value: 589, h: 2631},
    {key: 590, text: "Intel Core i7-2600K CPU @ 3.40GHz", value: 590, h: 2621},
    {key: 591, text: "Intel Xeon CPU E5-1650 0 @ 3.20GHz", value: 591, h: 2617},
    {key: 592, text: "Intel Core i5-9600 CPU @ 3.10GHz", value: 592, h: 2616},
    {key: 593, text: "Intel Xeon W-2223 CPU @ 3.60GHz", value: 593, h: 2608},
    {key: 594, text: "Intel Core i5-6600K CPU @ 3.50GHz", value: 594, h: 2603},
    {key: 595, text: "Intel Core i7-4770 CPU @ 3.40GHz", value: 595, h: 2600},
    {key: 596, text: "Intel Xeon CPU E3-1245 v5 @ 3.50GHz", value: 596, h: 2594},
    {key: 597, text: "Intel Xeon CPU E3-1270 v3 @ 3.50GHz", value: 597, h: 2594},
    {key: 598, text: "Intel Xeon CPU E3-1225 v5 @ 3.30GHz", value: 598, h: 2589},
    {key: 599, text: "Intel Xeon D-2141I CPU @ 2.20GHz", value: 599, h: 2583},
    


];
