import React from "react";
import "semantic-ui-css/semantic.min.css";
import { Form, Input } from "semantic-ui-react";
import {
  useForm,
} from "react-hook-form";
import { cpus } from "./cpus";

const nonces = {
  288: { n: 288, p: 0.9992, y: 0.021 },
  272: { n: 272, p: 0.9988, y: 0.031 },
  256: { n: 256, p: 0.9982, y: 0.046 },
  240: { n: 240, p: 0.9973, y: 0.067 },
  224: { n: 224, p: 0.996, y: 0.098 },
  208: { n: 208, p: 0.9941, y: 0.142 },
  192: { n: 192, p: 0.9913, y: 0.204 },
  176: { n: 176, p: 0.987, y: 0.288 },
  160: { n: 160, p: 0.9807, y: 0.397 },
  144: { n: 144, p: 0.9714, y: 0.53 },
  128: { n: 128, p: 0.9575, y: 0.676 },
};

const toHrMin = (seconds) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  return `${h}hr ${m}min`;
};

const nonceOptions = Object.entries(nonces)
  .sort((a, b) => b[1].n - a[1].n)
  .map((nonce) => {
    return {
      key: nonce[1].n,
      text: nonce[1].n,
      value: nonce[1].n,
    };
  });

const epochs = [
  {
    key: 0,
    text: "Any Epoch",
    value: 0,
  },
];

const margins = [
  {
    key: 0,
    text: "YOLO",
    value: 0,
  },
  {
    key: 0.5,
    text: "30 Minutes",
    value: 0.5,
  },
  {
    key: 1,
    text: "1 Hour",
    value: 1,
  },
  {
    key: 2,
    text: "2 Hours",
    value: 2,
  },
  {
    key: 3,
    text: "3 Hours",
    value: 3,
  },
];

// const storage = [
//   {
//     key: 0,
//     text: "HDD (7200 RPM)",
//     value: 0,
//   },
//   {
//     key: 1,
//     text: "SATA SSD",
//     value: 1,
//   },
//   {
//     key: 2,
//     text: "NVMe",
//     value: 2,
//   },
//   {
//     key: 3,
//     text: "Magnetic Tape",
//     value: 3,
//   },
// ];

export default function Calc() {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nonces: 288,
      margin: 1.0,
      epoch: 0,
      storage: 0,
    },
  });

  const onSubmit = (data) => console.log(data);

  const onChangeCPU = (e, data) => {
    let ix = data.value;
    let cpu = cpus[ix].h;
    setValue("score", cpu);
  };

  const [result, setResult] = React.useState({});

  React.useEffect(() => {
    const subscription = watch((data) => {
      if (!data.tib || data.tib <= 0 || !data.score || data.score <= 0) {
        setResult({});
        return;
      }

      const firstpass = nonces[data.nonces].p
        ? (nonces[data.nonces].p * 100).toFixed(2) + "%"
        : "";
      const yearpass = nonces[data.nonces].y
        ? (nonces[data.nonces].y * 100).toFixed(1) + "%"
        : "";

      const total = 12 * 60 * 60;
      const margin = data.margin * 60 * 60;
      const available = total - margin;

      const su = Math.ceil((data.tib * 1024) / 64);
      const network = data.epoch === 1 ? 4 : 1;
      const factor = data.nonces * su * network;

      // const callibration = 0.0000004059013705;
      const callibration = 2958.2275243994 / 4;
      const powS = Math.round((callibration * factor) / data.score, 0);
      const powT = toHrMin(powS);
      const ioS = available - powS;

      const reqIO = (1024.0 * data.tib) / ioS;
      const ioA = (1024.0 * data.tib) / data.gibs;
      const speed = ioA && ioA !== Infinity && ioA > 0 ? ioA : ioS;

      const ioT = ioS > 0 ? toHrMin(speed) : "N/A";

      const tT =
        powS + speed <= available
          ? toHrMin(powS + speed + margin)
          : ">12 Hours";
      const mT = toHrMin(margin);

      const success = powS + speed <= available && reqIO > 0;

      const drives = success
        ? {
            hddL: Math.ceil(reqIO / 0.17),
            hddS: Math.ceil(reqIO / 0.12),
            hdd5: Math.ceil(reqIO / 0.08),
            ssd: Math.ceil(reqIO / 0.46),
            nvme: Math.ceil(reqIO / 2.8),
          }
        : null;

      const chart = {
        pow: [0, powS / total],
        io: [0, speed / total],
        margin: [0, margin / total],
        finish: [0, (powS + speed + margin) / total],
      };

      chart.io[0] = chart.pow[1];
      chart.margin[0] = chart.pow[1] + chart.io[1];

      if (chart.pow[1] > 1) {
        chart.pow[1] = 1;
        chart.io = [0, 0];
        chart.margin = [0, 0];
        chart.finish = [0, 0];
      }

      if (chart.io[0] + chart.io[1] > 1) {
        chart.io[1] = 1 - chart.io[0];
        chart.margin = [0, 0];
        chart.finish = [0, 0];
      }

      if (chart.margin[0] + chart.margin[1] > 1) {
        chart.margin[1] = 1 - chart.margin[0];
        chart.finish = [0, 0];
      }

      let res = {
        su,
        factor,
        firstpass,
        yearpass,
        margin,
        available,
        powT,
        ioT,
        ioA,
        ioS,
        tT,
        speed,
        reqIO,
        mT,
        success,
        drives,
        chart,
      };

      setResult(res);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  React.useEffect(() => {
    if(window && window.location.host !== "plan.smesh.online"){
      window.location.href = "https://plan.smesh.online/"
    }
  }, [])

  return (
    <div style={{ padding: "10px", paddingBottom: "50px" }}>
      <div style={{ marginBottom: "2rem" }}>
        <h1>Smesh Planner - ALPHA VERSION v0.2</h1>
        <div>Updated 2023-10-21</div>
        <p>The planned difficulty increase in Epoch 11 has been scrapped.</p>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "120px 250px 1fr",
            columnGap: "20px",
            rowGap: "10px",
            padding: "10px",
            width: "1000px",
          }}
        >
          <div className="label">
            <div>Your CPU</div>
          </div>
          <div style={{ fontSize: "0.9rem" }}>
            <Form.Dropdown
              placeholder="Select CPU"
              fluid
              search
              selection
              options={cpus}
              onChange={onChangeCPU}
            />
          </div>
          <div className="note">
            Calculator assumes 100% CPU utilisation using all threads. If that's
            not the case then manually adjust the cpu power.
          </div>

          <div className="label">CPU Power</div>
          <div>
            <Input fluid placeholder="xmrig benchmark score">
              <input type="number" {...register("score")} />
            </Input>
          </div>
          <div className="note">
            Basically the xmrig benchmark score for above CPU. Note some CPUs
            have very low sample sizes.
            <br /> Calculator is callibrated to the actual smeshing performance
            of my own nodes then scaled against the xmrig score.
          </div>

          <div className="label">
            <div>Target Nonces</div>
          </div>
          <div>
            <Form.Dropdown
              placeholder="Nonces"
              fluid
              selection
              defaultValue={288}
              options={nonceOptions}
              onChange={(e, data) => setValue("nonces", data.value)}
            />
          </div>
          <div className="note">
            Higher nonces mean a better chance of finding a result in a single
            pass of your data, but requires more upfront computation.
          </div>

          <div className="label">
            <div>Safety Margin</div>
          </div>
          <div>
            <Form.Dropdown
              placeholder="Safety Margin"
              fluid
              selection
              defaultValue={1}
              options={margins}
              onChange={(e, data) => setValue("margin", data.value)}
            />
          </div>
          <div className="note">
            Aim to finish with at least this much time to spare.
          </div>

          <div className="label">
            <div>Epoch</div>
          </div>
          <div>
            <Form.Dropdown
              placeholder="Epoch"
              fluid
              selection
              defaultValue={0}
              options={epochs}
              onChange={(e, data) => setValue("epoch", data.value)}
            />
          </div>
          <div className="note">
            Epoch 11 difficulty increase has been scrapped
          </div>

          {/* <div className="label">
            <div>Storage Medium</div>
          </div>
          <div>
            <Form.Dropdown
              placeholder="Storage"
              fluid
              selection
              defaultValue={0}
              options={storage}
              onChange={(e, data) => setValue("storage", data.value)}
            />
          </div>
          <div className="note">... tell me you know what this is.</div> */}

          <div className="label">
            <div>Target Size</div>
          </div>
          <div>
            <Input
              className="taggedInput"
              placeholder="Target Size"
              labelPosition="right"
            >
              <input type="number" {...register("tib")} />
              <div className="ui basic label label">TiB</div>
            </Input>
          </div>
          <div className="note">
            Total amount of data you want to smesh on this machine. 0.25 TiB
            minimum.
          </div>

          <div className="label">
            <div>Total Read Speed</div>
          </div>
          <div>
            <Input
              className="taggedInput"
              placeholder="Read Speed"
              labelPosition="right"
            >
              <input type="number" {...register("gibs")} />
              <div className="ui basic label label">GiB/s</div>
            </Input>
          </div>
          <div className="note">
            Used to calculate Stage 2 time if provided. If using multiple disks enter the total read speed across all smeshing drives.
          </div>

          <div></div>
          {/* <div>
            <Button type="submit" style={{ width: "100%" }}>
              Calculate
            </Button>
          </div> */}
        </div>
      </Form>

      <div style={{border: '4px solid orange',
padding: '10px',
display: 'block',
width: 'fit-content'}}>
        <div style={{fontWeight: "bold"}}>Want 24 hours to finish proof instead of 12?</div>
        <div style={{textAlign: 'center',}}><a style={{textDecoration: 'underline',
color: 'black'}}href="https://discord.gg/Y2t2rMkthJ" target="_blank" rel="noreferrer">Join Team24 🚀</a></div>
      </div>

      {result && result.reqIO && (
        <>
          <div style={{ padding: "10px" }}>
            <h3>Result</h3>

            <table style={{ borderCollapse: "collapse" }}>
              <colgroup>
                <col style={{ width: "200px" }} />
                <col style={{ width: "200px" }} />
              </colgroup>
              <tbody>
                <tr>
                  <td>Stage 1</td>
                  <td>{result.powT}</td>
                </tr>
                <tr>
                  <td>Stage 2</td>
                  <td>{result.ioT}</td>
                </tr>
                <tr>
                  <td>Margin</td>
                  <td>{result.mT}</td>
                </tr>
                <tr style={{ fontWeight: "bold" }}>
                  <td style={{ paddingBottom: "5px" }}>Total Time</td>
                  <td
                    style={{
                      paddingBottom: "5px",
                      color: result.success ? "#000" : "red",
                    }}
                  >
                    {result.tT}
                  </td>
                </tr>

                <tr style={{ fontWeight: "bold" }}>
                  <td>Required Read Speed</td>
                  {result.reqIO && result.reqIO > 0 && (
                    <td>{result.reqIO.toFixed(2)} GiB/s</td>
                  )}
                  {result.reqIO && result.reqIO <= 0 && <td>Impossible</td>}
                </tr>

                {result.drives && (
                  <>
                    <tr>
                      <td
                        colSpan={2}
                        style={{ paddingTop: "20px", fontStyle: "italic" }}
                      >
                        Suggested number of disks for required speed:
                      </td>
                    </tr>
                    <tr>
                      <td>HDD (&gt;8TB):</td>
                      <td>{result.drives.hddL}</td>
                    </tr>
                    <tr>
                      <td>HDD (&lt;8TB):</td>
                      <td>{result.drives.hddS}</td>
                    </tr>
                    <tr>
                      <td>HDD (5400RPM):</td>
                      <td>{result.drives.hdd5}</td>
                    </tr>
                    <tr>
                      <td>SATA SSD:</td>
                      <td>{result.drives.ssd}</td>
                    </tr>
                    <tr>
                      <td>NVMe:</td>
                      <td>{result.drives.nvme}</td>
                    </tr>
                  </>
                )}

                <tr>
                  <td style={{ paddingTop: "12px" }}>Single Read Success</td>
                  <td style={{ paddingTop: "12px" }}>{result.firstpass}</td>
                </tr>
                <tr>
                  <td>Need &gt;1 read within a year</td>
                  <td>{result.yearpass}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <p style={{ paddingLeft: "10px" }}>
            Proof times are ESTIMATED and based on AVERAGE performance. Variance
            should be expected.
          </p>

          {result && result.chart && (
            <div style={{ padding: "10px", marginBottom: "20px" }}>
              <div
                style={{
                  width: "600px",
                  height: "50px",
                  border: "1px solid #CCC",
                  marginBottom: "1rem",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: `${result.chart.pow[1] * 100}%`,
                    backgroundColor: "yellow",
                    position: "absolute",
                    left: 0,
                    top: 0,
                  }}
                ></div>
                <div
                  style={{
                    height: "100%",
                    width: `${result.chart.io[1] * 100}%`,
                    backgroundColor: "blue",
                    position: "absolute",
                    left: `${result.chart.io[0] * 100}%`,
                    top: 0,
                  }}
                ></div>
                <div
                  style={{
                    height: "100%",
                    width: `${result.chart.margin[1] * 100}%`,
                    backgroundColor: "green",
                    position: "absolute",
                    left: `${result.chart.margin[0] * 100}%`,
                    top: 0,
                  }}
                ></div>
                <div
                  style={{
                    height: "160%",
                    width: `${result.success ? "3px" : 0}`,
                    backgroundColor: "black",
                    position: "absolute",
                    left: `${result.chart.finish[1] * 100}%`,
                    top: "-30%",
                  }}
                ></div>
                <div
                  style={{
                    height: "100%",
                    width: `${result.chart.margin[1] * 100}%`,
                    backgroundColor: "green",
                    position: "absolute",
                    left: `${result.chart.margin[0] * 100}%`,
                    top: 0,
                  }}
                ></div>
                <div
                  style={{
                    height: "160%",
                    width: "3px",
                    backgroundColor: "red",
                    position: "absolute",
                    left: "100%",
                    top: "-30%",
                  }}
                ></div>
              </div>

              <div style={{ display: "flex", paddingTop: "30px" }}>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px",
                      backgroundColor: "yellow",
                    }}
                  ></div>
                  <div>Stage 1 - PoW</div>
                </div>
                <div style={{ display: "flex", margin: "0 20px" }}>
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px",
                      backgroundColor: "blue",
                    }}
                  ></div>
                  <div>Stage 2 - I/O</div>
                </div>
                <div style={{ display: "flex", margin: "0 20px" }}>
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px",
                      backgroundColor: "green",
                    }}
                  ></div>
                  <div>Safety Margin</div>
                </div>

                <div
                  style={{
                    display: "flex",
                    margin: "0 20px",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "20px",
                      height: "4px",
                      marginRight: "10px",
                      backgroundColor: "black",
                    }}
                  ></div>
                  <div>Finish</div>
                </div>

                <div
                  style={{
                    display: "flex",
                    margin: "0 20px",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "20px",
                      height: "4px",
                      marginRight: "10px",
                      backgroundColor: "red",
                    }}
                  ></div>
                  <div>Deadline</div>
                </div>
              </div>
            </div>
          )}

          <div style={{ padding: "10px", marginTop: "2rem" }}>
            <h3>What Next?</h3>
            <p>Do a sanity check.</p>
            <p>
              If you haven't entered a GiB/s the calculator simply tells you the
              minimum GiB/s you need. If your system can't supply that read
              speed you won't finish in time!
            </p>
            <p>
              Use the smapp or go-spacemesh profiler tool to check your read
              speed and treat the result as a 'best case'. To improve
              performance either reduce nonces, use faster disks, or create a
              striped RAID.
            </p>
            <p>
              If you have time to spare you can run postcli -genproof
              -logLevel=debug.
              <br />
              Note the reference postcli is locked to 16 nonces and 1 thread for
              -genproof currently, so either edit source or wait for updates.
            </p>
            <br />
          </div>
        </>
      )}

      <hr />
      <div style={{ padding: "10px" }}>
        <h3>What does this do?</h3>
        <p>
          This tool aims to help answer some questions for mining Spacemesh
          (smeshing):
        </p>
        <ul>
          <li>How much data can my CPU handle?</li>
          <li>What storage do I need to support the CPU?</li>
          <li>What difference does number of nonces make?</li>
          <li>Will I be able to smesh if the network difficulty increases?</li>
        </ul>
        <p></p>
        <h3>Why not accurate?</h3>
        <p>
          The purpose of the tool at this stage is to help people understand how
          the variables interact, not to give a definitive answer on what you'll
          be able to smesh.
        </p>
        <p>
          This tool was callibrated to the actual performance of my node. All other CPUs are estimated based on scaling
          performance in line with xmrig scores. It's not ideal but it's the
          best I've got right now.
        </p>
        <p>
          Spacemesh PoW benchmarking tools are not yet readily accessible to the
          community. Once that changes more accurate data will be available and
          you'll be able to plug in your actual performance.
        </p>

        <h3>Read Success</h3>
        <p>
          Fewer nonces mean a greater chance of having to perform the proof
          process more than once.
        </p>
        <p>
          We're interested in this value because for large nodes this
          effectively means a fail and no rewards for the next epoch.
        </p>
        <p>
          For small nodes with fast storage it may be preferable to reduce
          nonces as it can reduce total work done.
        </p>

        <h3>Read Speed</h3>
        <p>
          My suggested safe values per disk when trying to maximize node size.
          Use them, or don't.
        </p>
        <ul>
          <li>NVMe: 2.8 GiB/s</li>
          <li>SATA SSD: 0.46 GiB/s</li>
          <li>HDD (7200RPM, &gt;8TB): 0.17 GiB/s</li>
          <li>HDD (7200RPM, &lt;8TB): 0.12 GiB/s</li>
          <li>HDD (5400RPM): 0.08 GiB/s</li>
        </ul>
        <p>
          The only way to get the true measure of your system is to measure
          actual performance during proof generation (ie postcli -genproof).
        </p>
        <p>
          Treat the profiler tool and any disk benchmarking tools as an upper
          bound.
        </p>

        <h3>What happens during the PoET window?</h3>
        <p>
          You have 12 hours to prove that you have the data you claim to have.
        </p>
        <p>
          When the window starts your node requests 'proof of elapsed time' from
          PoET servers which forms part of the proof that you still
          have the data you registered with the PoET at the previous epoch.
          That's why it takes two cycles to start receiving rewards, the first
          is a registration and the second onwards proves you've kept the data
          for an epoch.
        </p>
        <p>
          This is a <span style={{ fontWeight: "bold" }}>two</span> step
          process:
        </p>
        <p>
          Stage 1: Perform proof of work (based on RandomX) to find a valid
          'nonce' for each nonce group. You have one group for every 16 nonces
          in your smeshing options ie 192 nonces = 12 groups. The amount of work
          required increases with both nonces and data. The difficulty scales
          linearly with number of nonce groups and number of space units (amount
          of data).
        </p>
        <p>
          Stage 2: <span style={{ fontStyle: "italic " }}>After</span> finding a
          nonce for all nonce groups you start reading and processing your PoS
          data. Each nonce calculated in the previous step gives you an
          "attempt" to find a valid solution. There is an element of PoW
          throughout but this phase is mostly I/O bound.
        </p>
        <p>
          {" "}
          If none of your nonces produce a solution you'll go back to the stage
          1 to calculate some new nonces, then to step 2 to read through all of
          the data again. This repeats until a solution is found or you run out
          of time.
        </p>
        <p>
          Note that the amount of work required in step 1 is non-deterministic.
          You could find a nonce within a few hashes or millions of hashes
          later. This is why its important to have a safety margin.
        </p>

        <h3>🥔</h3>
        <p>sm1qqqqqq8hfg45e2s0jlrr79rggzjlpkpt90ra7zgd5q8uz</p>
      </div>

      
    </div>
  );
}